<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <l-map
            style="height: 300px"
            :zoom="zoom"
            :center="center"
            @click="onMapClick"
            ref="map"
            @ready="onReady"
            @baselayerchange="layerChanged"
        >
            <l-control-layers position="topright" :collapsed="false" :sort-layers="false" />
            <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :subdomains="tileProvider.subdomains"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                layer-type="base"
            />

            <component
                v-if="item.mapElements"
                :key="index"
                v-for="(element, index) in item.mapElements"
                :is="element"
            />

            <l-marker v-if="selectedPoint" :lat-lng="selectedPoint" v-bind="markerOptions()">
                <l-popup style="width: 130px" class="text-center">
                    <div class="p-2 text-nowrap">
                        {{ local }}
                    </div>
                    <b-btn size="sm" class="btn btn-danger" @click="selectedPoint = null" v-if="!isDisabledOrReadOnly">
                        {{ $l("platon.map_remove_marker", "Ўчириш") }}
                    </b-btn>
                </l-popup>
            </l-marker>
        </l-map>
    </BaseControl>
</template>

<script>
import { latLng } from "leaflet"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import ValidationMixin from "@Platon/mixins/ValidationMixin"

import MapTiles from "@Platon/components/map/MapTiles"
import "@Platon/components/map/VueLeafletImport"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"
import CreateLatLng from "@Platon/core/helpers/mapHelpers"

export default {
    name: "MapControl",

    components: {
        BaseControl
    },

    mixins: [ValidationMixin, InputControl2Mixin],

    props: {
        value: {}
    },

    data() {
        let center = this.item.mapCenter ? latLng(...this.item.mapCenter.split(",")) : latLng(41.311081, 69.240562)
        let zoom = this.item.mapZoom ? Number(this.item.mapZoom) : 12
        let activatedMapTiles = MapTiles.map((element) => {
            element.visible = element.name === this.item.activeMap

            return element
        })

        return {
            selectedPoint: null,
            center,
            zoom,
            tileProviders: activatedMapTiles,
            local: null,
            map: null
        }
    },

    mounted() {
        this.fixInTab()

        if (this.value && this.value.includes(",")) {
            this.selectedPoint = latLng(...this.value.split(","))
            this.center = this.selectedPoint
        }
        this.initMapControls()
    },

    methods: {
        getMap() {
            return this.$refs.map.mapObject
        },
        async getFullScreenDependency() {
            await Promise.all([
                this.addStyle("https://unpkg.com/platon-leaflet-fullscreen@0.0.1/dist/style.css"),
                this.addScript("https://unpkg.com/platon-leaflet-fullscreen@0.0.1/dist/fullscreen.js")
            ])
        },
        async getGeoCoderDependency() {
            await Promise.all([
                this.addStyle("https://unpkg.com/leaflet-geonames@0.4.9/L.Control.Geonames.css"),
                this.addScript("https://unpkg.com/leaflet-geonames@0.4.9/L.Control.Geonames.min.js")
            ])
        },
        async initSearchControl() {
            await this.getGeoCoderDependency()
            await this.wait(500)
            const map = this.getMap()
            const control = L.control.geonames({
                //position: 'topcenter', // In addition to standard 4 corner Leaflet control layout, this will position and size from top center.
                position: "topleft",
                country: "UZ",
                geonamesSearch: "https://secure.geonames.org/searchJSON", // Override this if using a proxy to get connection to geonames.
                geonamesPostalCodesSearch: "https://secure.geonames.org/postalCodeSearchJSON", // Override this if using a proxy to get connection to geonames.
                username: "shohrux_saidov", // Geonames account username.  Must be provided.
                maxresults: 15, // Maximum number of results to display per search.
                zoomLevel: null, // Max zoom level to zoom to for location. If null, will use the map's max zoom level.
                className: "leaflet-geonames-icon", // Class for icon.
                workingClass: "leaflet-geonames-icon-working", // Class for search underway.
                featureClasses: ["A", "H", "L", "P", "R", "S", "V"], // Feature classes to search against.  See: http://www.geonames.org/export/codes.html.
                baseQuery: "isNameRequired=true", // The core query sent to GeoNames, later combined with other parameters above.
                showMarker: false, // Show a marker at the location the selected location.
                showPopup: true, // Show a tooltip at the selected location.
                adminCodes: {
                    country: "UZ"
                }, // Filter results by the specified admin codes mentioned in `ADMIN_CODES`. Each code can be a string or a function returning a string. `country` can be a comma-separated list of countries.
                bbox: {}, // An object in form of {east:..., west:..., north:..., south:...}, specifying the bounding box to limit the results to.
                lang: "uz", // Locale of results.
                alwaysOpen: false, // If true, search field is always visible.
                title: "Search by location name or postcode", // Search input title value.
                placeholder: "Enter a location name" // Search input placeholder text.
            })
            map.addControl(control)
        },
        async initFullControl() {
            await this.getFullScreenDependency()
            await this.wait(500)
            this.getMap().addControl(
                new L.Control.Fullscreen({
                    title: {
                        false: this.$l("fullScreen", "Toliq qilib ochish"),
                        true: this.$l("exitFromFullScreen", "To'liq ekrandan chiqish")
                    }
                })
            )
        },
        async wait(time) {
            return new Promise((resolve) => {
                setTimeout(resolve, time)
            })
        },
        initMapControls() {
            this.initFullControl()
            this.initSearchControl()
        },
        /**
         * @param {LatLng} e.latlng
         */
        async onMapClick(e) {
            if (this.isDisabledOrReadOnly) return

            if (
                typeof this.item.validateMarker === "function" &&
                !(await this.item.validateMarker(e.latlng.wrap(), e))
            ) {
                return
            }

            this.selectedPoint = e.latlng.wrap()
        },

        /**
         * @param {Map} map
         */
        onReady(map) {
            this.map = map
            map.flyTo(this.center, this.zoom)
        },

        // Google and OpenStreetMap use EPSG3857 projection, but Yandex use EPSG3395, so we need toggle it when toggle Yandex layer
        layerChanged(layer) {
            this.$nextTick(() => {
                const map = this.map
                const center = map.getCenter()
                if (layer.name.includes("Yandex")) {
                    map.options.crs = L.CRS.EPSG3395
                } else {
                    map.options.crs = L.CRS.EPSG3857
                }
                map.setView(center)
                map._resetView(map.getCenter(), map.getZoom(), true)
            })
        },

        fixInTab() {
            let parent = this.$parent

            while (parent) {
                if (parent.$options.name === "BTab") {
                    parent.$on("click", () => {
                        this.$refs.map.mapObject._onResize()
                    })

                    break
                }

                parent = parent.$parent
            }
        }
    },

    computed: {
        // tileProvidersNew() {
        // 	console.log(this.item.activeMap)

        // },
        markerOptions() {
            return this.item.markerOptions ? this.item.markerOptions : () => {}
        }
    },

    watch: {
        /**
         * @param {LatLng} val
         */
        selectedPoint(val) {
            if (!val) {
                this.local = null
            } else {
                this.local = `${val.lat.toFixed(6)},${val.lng.toFixed(6)}`
            }

            this.$emit("input", this.local)
        },

        value: {
            handler(val) {
                if (val !== this.local) {
                    this.selectedPoint = CreateLatLng(val)
                    this.center = this.selectedPoint
                }
            }
        }
    }
}
</script>

<style></style>
